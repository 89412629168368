import { Link } from 'modules/common/components/Link/Link'
import { IconLightBulb } from 'modules/common/components/_icons/icons'
import { BannerMessage } from 'modules/common/components/_ui/BannerMessage/BannerMessage'
import { useTrackEvent } from 'modules/common/hooks/useTrackEvent'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { EnumRoutes } from 'modules/common/routes'
import { EnumHomeTrackingEvents } from 'modules/home/lib/tracking/events'
import { EnumServiceSlugs } from 'modules/services/types/services'
import { Trans } from 'next-i18next'

interface Props {
  onClose?: () => void
}

export const TDAEDiscoveryBannerMessage = ({ onClose }: Props) => {
  const { t } = useTranslation('home-components')

  const trackEvent = useTrackEvent()

  return (
    <BannerMessage icon={<IconLightBulb />} type="important" onClose={onClose}>
      <Trans
        components={{
          linkComponent: (
            <Link
              href={`${EnumRoutes.SERVICES}/${EnumServiceSlugs.TDAE_URSSAF}`}
              className="text-primary"
              onClick={() =>
                trackEvent({
                  eventType: EnumHomeTrackingEvents.TDAE_INTEREST_HOMEPAGE,
                })
              }
            />
          ),
        }}
      >
        {t('tdaeDiscoveryBannerMessage')}
      </Trans>
    </BannerMessage>
  )
}
