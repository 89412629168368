import { RoundedElevatedCard } from 'modules/common/components/RoundedElevatedCard/RoundedElevatedCard'
import { Skeleton } from 'modules/common/components/_ui/Skeletons/Skeleton/Skeleton'
import { BankingLimitProgressSkeleton } from '../BankingLimitProgress/BankingLimitProgressSkeleton'

interface Props {
  withCurrentExpenses?: boolean
}

export const BankingLimitsCardSkeleton = ({ withCurrentExpenses }: Props) => (
  <RoundedElevatedCard className="w-full">
    <div className="flex items-center justify-between">
      <Skeleton className="h-6 w-44" />
      <Skeleton className="h-4 w-24" />
    </div>
    {withCurrentExpenses ? (
      <div className="mt-4 divide-y divide-info-100">
        <BankingLimitProgressSkeleton className="pb-5" />
        <BankingLimitProgressSkeleton className="pt-5" />
      </div>
    ) : (
      <div className="mt-5 w-full divide-y divide-info-100">
        <div className="space-y-3 pb-5">
          <Skeleton className="h-4 w-28" />
          <Skeleton className="h-4 w-24" />
        </div>
        <div className="space-y-3 pb-1 pt-5">
          <Skeleton className="h-4 w-28" />
          <Skeleton className="h-4 w-28" />
        </div>
      </div>
    )}
  </RoundedElevatedCard>
)
