import { LimitParametersRuleApi } from '@blank/api'
import { useIncreaseTransferLimitsRequestModal } from 'modules/bank/modals/transfer/IncreaseTransferLimitsRequestModal/useIncreaseTransferLimitsRequestModal'
import { RoundedElevatedCard } from 'modules/common/components/RoundedElevatedCard/RoundedElevatedCard'
import { IconPen } from 'modules/common/components/_icons/icons'
import { Button } from 'modules/common/components/_ui/Button/Button'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { EnumRoutes } from 'modules/common/routes'
import { BankingLimitProgress } from '../BankingLimitProgress/BankingLimitProgress'
import { BankingLimits } from './BankingLimits/BankingLimits'

export enum EnumBankingLimitsCardType {
  TRANSFER = 'transfer',
  PAYMENT = 'payment',
}

export interface BankingLimitsCardProps {
  type: EnumBankingLimitsCardType
  dailyLimit: LimitParametersRuleApi
  monthlyLimit: LimitParametersRuleApi
  className?: string
  isManageLimitsButtonDisabled?: boolean
  withCurrentExpenses?: boolean
}

export const BankingLimitsCard = ({
  type,
  dailyLimit,
  monthlyLimit,
  className,
  isManageLimitsButtonDisabled = false,
  withCurrentExpenses,
}: BankingLimitsCardProps) => {
  const { t } = useTranslation('bank-components')
  const { showModal: showIncreaseTransferLimitsRequestModal } =
    useIncreaseTransferLimitsRequestModal()

  return (
    <RoundedElevatedCard className={className}>
      <div className="flex flex-wrap justify-between">
        <h3>{t('bankingLimitsCard.title', { context: type })}</h3>
        <Button
          variant="tertiary"
          className="!p-0"
          color="default"
          icon={<IconPen />}
          disabled={isManageLimitsButtonDisabled}
          {...(type === EnumBankingLimitsCardType.PAYMENT
            ? {
                href: EnumRoutes.ACCOUNT_CARD,
              }
            : {
                onClick: showIncreaseTransferLimitsRequestModal,
                type: 'button',
              })}
        >
          {t('bankingLimitsCard.manageLimits')}
        </Button>
      </div>
      {withCurrentExpenses ? (
        <div className="divide-y divide-info-100">
          <BankingLimitProgress
            limit={dailyLimit}
            frequency="daily"
            className="py-4"
          />
          <BankingLimitProgress
            limit={monthlyLimit}
            frequency="monthly"
            className="pt-4"
          />
        </div>
      ) : (
        <BankingLimits
          className="mt-4"
          dailyLimit={dailyLimit}
          monthlyLimit={monthlyLimit}
        />
      )}
    </RoundedElevatedCard>
  )
}
