import animationData from 'assets/lotties/dsp2-announcement.json'
import Lottie from 'lottie-react'
import { Button } from 'modules/common/components/_ui/Button/Button'
import { useCountryExternalLinks } from 'modules/common/hooks/useCountryExternalLinks'
import { useTranslation } from 'modules/common/hooks/useTranslation'

export const DSP2AnnouncementModalContent = () => {
  const { t } = useTranslation('bank-components')

  const { dsp2Introduction } = useCountryExternalLinks()

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-4">
        <Lottie animationData={animationData} loop={true} />
        <h2>{t('DSP2AnnouncementModal.title')}</h2>
        <p>{t('DSP2AnnouncementModal.text')}</p>
      </div>
      <Button
        variant="primary"
        color="default"
        href={dsp2Introduction}
        target="_blank"
      >
        {t('DSP2AnnouncementModal.buttonLabel')}
      </Button>
    </div>
  )
}
