import {
  BankingLimitsCard,
  EnumBankingLimitsCardType,
} from 'modules/bank/components/account/BankingLimitsCard/BankingLimitsCard'
import { BankingLimitsCardSkeleton } from 'modules/bank/components/account/BankingLimitsCard/BankingLimitsCardSkeleton'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { InternalAccountCardError } from '../InternalAccountCardError/InternalAccountCardError'
import { useInternalAccountTransferLimitsQueries } from './useInternalAccountTransferLimitsQueries'

interface Props {
  withCurrentExpenses?: boolean
}

export const InternalAccountTransferLimitsCardContainer = ({
  withCurrentExpenses,
}: Props) => {
  const { t } = useTranslation('bank-components')

  const {
    data: {
      transferLimits: { monthlyLimit, dailyLimit },
    },
    isError,
    isLoading,
    errorRefetch,
  } = useInternalAccountTransferLimitsQueries()

  const cardType = EnumBankingLimitsCardType.TRANSFER

  if (isError) {
    return (
      <InternalAccountCardError
        title={t('bankingLimitsCard.title', { context: cardType })}
        onReload={errorRefetch}
      />
    )
  }

  if (isLoading || !(monthlyLimit && dailyLimit)) {
    return <BankingLimitsCardSkeleton />
  }

  return (
    <BankingLimitsCard
      type={cardType}
      dailyLimit={dailyLimit}
      monthlyLimit={monthlyLimit}
      withCurrentExpenses={withCurrentExpenses}
    />
  )
}
