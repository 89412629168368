import { useCountryConfig } from 'modules/common/hooks/useCountryConfig'
import { useOneTimeAnnouncementModal } from 'modules/common/modals/useOneTimeAnnouncementModal/useOneTimeAnnouncementModal'
import { EnumOneTimeAnnouncementKey } from 'modules/common/types/oneTimeAnnouncement'
import { useCorporateLifecycleQueries } from 'modules/corporate/hooks/useCorporateLifecycleQueries'
import { DSP2AnnouncementModalContent } from './DSP2AnnouncementModalContent'

export const useDSP2AnnouncementModal = () => {
  const {
    data: { isKycComplete },
    isSuccess,
  } = useCorporateLifecycleQueries()

  const { shouldDisplayDsp2AnnouncementModal } = useCountryConfig()

  return useOneTimeAnnouncementModal({
    width: 'base',
    announcementKey: EnumOneTimeAnnouncementKey.DSP2,
    displayCondition:
      shouldDisplayDsp2AnnouncementModal && isKycComplete && isSuccess,
    content: <DSP2AnnouncementModalContent />,
  })
}
