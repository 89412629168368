import { CardApi } from '@blank/api'
import { useQueryWithAuth } from 'modules/auth/hooks/useQueryWithAuth'
import { QueryOptions } from 'modules/auth/types/queries'
import { fetchAccountCards } from '../api/fetchAccountCards'
import { EnumBankQueryKeys } from './keys'

export const useAccountCardsQuery = (
  accountId?: string,
  queryOptions?: QueryOptions<CardApi[], Error>
) => {
  return useQueryWithAuth<CardApi[], Error>({
    queryKey: [EnumBankQueryKeys.ACCOUNT_CARDS_LIST],
    queryFn: async (_context, accessToken) => {
      if (!accountId) {
        throw new Error('useAccountCardsQuery: Missing parameter accountId')
      }

      return fetchAccountCards({ accountId, accessToken })
    },
    ...queryOptions,
    enabled: !!accountId,
  })
}
