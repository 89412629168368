import { FetchAccountCardsResponseApi } from '@blank/api'
import { apiClient } from 'modules/common/http/apiClient'
import { getAuthorizationHeader } from 'modules/common/lib/api/getAuthorizationHeader'

interface Props {
  accountId: string
  accessToken: string
}

export const fetchAccountCards = async ({ accountId, accessToken }: Props) => {
  const apiUrl = `/v1/bank/accounts/${accountId}/cards`

  const { data } = await apiClient.get<FetchAccountCardsResponseApi>(apiUrl, {
    headers: getAuthorizationHeader(accessToken),
  })

  return data?.data
}
