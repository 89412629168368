import { EnumCardStatusCode } from '@blank/api'
import { EnumBankingLimitsCardType } from 'modules/bank/components/account/BankingLimitsCard/BankingLimitsCard'
import { BankingLimitsCardSkeleton } from 'modules/bank/components/account/BankingLimitsCard/BankingLimitsCardSkeleton'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { InternalAccountCardError } from '../InternalAccountCardError/InternalAccountCardError'
import { BankCardActivationCard } from './BankCardActivationCard/BankCardActivationCard'
import { BankCardLimitsCardContainer } from './BankCardLimitsCardContainer/BankCardLimitsCardContainer'
import { useBankCardContainerQueries } from './useBankCardContainerQueries'

export const BankCardContainer = () => {
  const { t } = useTranslation('bank-components')

  const {
    data: { corporate, currentCard, offer, internalAccount },
    isError,
    isLoading,
    refetch,
  } = useBankCardContainerQueries()

  if (isError) {
    return (
      <InternalAccountCardError
        title={t('bankingLimitsCard.title', {
          context: EnumBankingLimitsCardType.PAYMENT,
        })}
        onReload={refetch}
      />
    )
  }

  if (isLoading || !(currentCard && corporate && offer && internalAccount)) {
    return <BankingLimitsCardSkeleton />
  }

  return currentCard?.statusCode === EnumCardStatusCode.PREACTIVATED ? (
    <BankCardActivationCard offer={offer} />
  ) : (
    <BankCardLimitsCardContainer
      currentCard={currentCard}
      isBlockedInvest={!!internalAccount.isBlockedInvest}
    />
  )
}
