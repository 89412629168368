import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { EnumLocalStorageKeys } from '_constants'
import { EnumOneTimeAnnouncementKey } from '../types/oneTimeAnnouncement'

interface OneTimeAnnouncementsStore {
  alreadySeenAnnouncements: EnumOneTimeAnnouncementKey[]
}
interface OneTimeAnnouncementsActions {
  addToAlreadySeenAnnouncements: (
    announcementKey: EnumOneTimeAnnouncementKey
  ) => void
  clearAlreadySeenAnnouncements: () => void
}

const useOneTimeAnnouncementsStore = create(
  persist<OneTimeAnnouncementsStore>(
    () => ({
      alreadySeenAnnouncements: [],
    }),
    {
      name: EnumLocalStorageKeys.ONE_TIME_ANNOUNCEMENTS,
    }
  )
)

export const useAlreadySeenAnnouncements = () =>
  useOneTimeAnnouncementsStore((state) => state.alreadySeenAnnouncements)

export const useHasSeenAnnouncement = (
  announcementKey: EnumOneTimeAnnouncementKey
) =>
  useOneTimeAnnouncementsStore((state) =>
    state.alreadySeenAnnouncements.includes(announcementKey)
  )

export const useOneTimeAnnouncementsActions =
  (): OneTimeAnnouncementsActions => {
    const { setState } = useOneTimeAnnouncementsStore

    return {
      addToAlreadySeenAnnouncements: (announcementKey) => {
        setState(({ alreadySeenAnnouncements }) => {
          if (alreadySeenAnnouncements.includes(announcementKey)) {
            return { alreadySeenAnnouncements }
          }
          return {
            alreadySeenAnnouncements: [
              ...alreadySeenAnnouncements,
              announcementKey,
            ],
          }
        })
      },
      clearAlreadySeenAnnouncements: () => {
        setState({ alreadySeenAnnouncements: [] })
      },
    }
  }
