import {
  useHasSeenAnnouncement,
  useOneTimeAnnouncementsActions,
} from '../store/useOneTimeAnnouncementsStore'
import { EnumOneTimeAnnouncementKey } from '../types/oneTimeAnnouncement'

interface Props {
  announcementKey: EnumOneTimeAnnouncementKey
  displayCondition?: boolean
}

export const useOneTimeAnnouncement = ({
  announcementKey,
  displayCondition = true,
}: Props) => {
  const { addToAlreadySeenAnnouncements } = useOneTimeAnnouncementsActions()
  const hasSeenAnnouncement = useHasSeenAnnouncement(announcementKey)

  const isAnnouncementToBeDisplayed = displayCondition && !hasSeenAnnouncement
  const setHasSeenAnnouncement = () =>
    addToAlreadySeenAnnouncements(announcementKey)

  return {
    isAnnouncementToBeDisplayed,
    setHasSeenAnnouncement,
  }
}
