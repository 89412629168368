import { CardApi } from '@blank/api'
import {
  BankingLimitsCard,
  EnumBankingLimitsCardType,
} from 'modules/bank/components/account/BankingLimitsCard/BankingLimitsCard'
import { BankingLimitsCardSkeleton } from 'modules/bank/components/account/BankingLimitsCard/BankingLimitsCardSkeleton'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { InternalAccountCardError } from '../../InternalAccountCardError/InternalAccountCardError'
import { useInternalCardLimitsQueries } from './useBankCardLimitsQueries'

interface Props {
  currentCard: CardApi
  isBlockedInvest: boolean
}

export const BankCardLimitsCardContainer = ({
  currentCard,
  isBlockedInvest,
}: Props) => {
  const { t } = useTranslation('bank-components')

  const {
    data: { monthlyLimit, dailyLimit },
    isError,
    isLoading,
    refetch,
  } = useInternalCardLimitsQueries(currentCard)

  const cardType = EnumBankingLimitsCardType.PAYMENT

  if (isError) {
    return (
      <InternalAccountCardError
        title={t('bankingLimitsCard.title', { context: cardType })}
        onReload={refetch}
      />
    )
  }

  if (isLoading || !(dailyLimit && monthlyLimit)) {
    return <BankingLimitsCardSkeleton />
  }

  return (
    <BankingLimitsCard
      type={cardType}
      dailyLimit={dailyLimit}
      monthlyLimit={monthlyLimit}
      isManageLimitsButtonDisabled={isBlockedInvest}
      withCurrentExpenses
    />
  )
}
