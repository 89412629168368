import { EnumOneTimeAnnouncementKey } from './../../types/oneTimeAnnouncement'
import { useOneTimeAnnouncement } from 'modules/common/hooks/useOneTimeAnnouncement'
import { useEffect, ReactNode } from 'react'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import {
  ContentModalProps,
  useContentModal,
} from '../useContentModal/useContentModal'

type Props = Pick<ContentModalProps, 'width'> & {
  announcementKey: EnumOneTimeAnnouncementKey
  displayCondition: boolean
  content: ReactNode
}
export const useOneTimeAnnouncementModal = ({
  width,
  announcementKey,
  displayCondition,
  content,
}: Props) => {
  const { setHasSeenAnnouncement, isAnnouncementToBeDisplayed } =
    useOneTimeAnnouncement({ announcementKey, displayCondition })

  const { i18n, t } = useTranslation('common')

  const modal = useContentModal({
    width,
    content,
    i18n,
    headerTitle: t('new'),
    overlayTransitionType: 'transition-none',
  })

  useEffect(() => {
    if (isAnnouncementToBeDisplayed) {
      modal.showModal()
      setHasSeenAnnouncement()
    }
  }, [setHasSeenAnnouncement, isAnnouncementToBeDisplayed, modal])

  return modal
}
